<template>
  <div class="accountManagement">
    <div style="border-bottom: 1px solid #edeff2">
      <div class="moTitle">
        任务模版设置
        <div>
          <span style="color: #c1c1c1; font-weight: 500"
            >最多支持自定义5个模板</span
          >&ensp;
          <a-button type="primary" @click="addTemplate"> +创建模板</a-button>
        </div>
      </div>
    </div>
    <div class="table">
      <a-table
        :columns="invoice"
        :data-source="invoiceData"
        :pagination="false"
      >
        <span slot="isSystem" slot-scope="text, record">
          <a-switch
              :disabled="record.name === '系统模板'"
            :checked="record.isOpen === 1 ? true : false"
            checked-children="开"
            un-checked-children="关"
            @change="cardSwitch(record.isOpen === 0 ? 1 : 0, record)"
            default-checked
          />
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="look(record)">查看</a>&ensp;
          <a-popconfirm
            title="是否确认删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="del(record.code)"
            ><a href="#" v-if="record.isSystem !== 1">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <!-- 分页 -->
      <!-- <div
      style="
        display: block;
        width: 100%;
        height: 50px;
        text-align: center;
        margin-top: 45px;
      "
    >
      <a-pagination
        :current="pageNo"
        :total="total"
        :pageSize="pageSize"
        show-quick-jumper
        @change="PageNOchange"
        style="display: inline-block"
      />
    </div> -->
    </div>
    <!--新增对话框 -->
    <a-modal
      @cancel="cancel"
      title="创建模板"
      :visible="visible"
      width="460px"
      @ok="handleOk"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="模板名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="模板描述" prop="detail">
          <a-textarea v-model="form.detail" placeholder="请输入" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getTemplateList,
  addTemplate,
  editTemplate,
  delTemplate,
} from "@/service/meetingTemplat_api.js";

export default {
  data() {
    return {
      invoice: [
        {
          title: "code",
          dataIndex: "code",
          key: "code",
        },
        {
          title: "模版名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "模版描述",
          dataIndex: "detail",
          key: "detail",
        },
        {
          title: "创建人",
          dataIndex: "createdByName",
          key: "createdByName",
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          key: "createdTime",
        },
        {
          title: "状态",
          dataIndex: "isSystem",
          key: "isSystem",
          scopedSlots: { customRender: "isSystem" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      invoiceData: [],
      pageNo: 1,
      pageSize: 999999,
      total: 0,
      visible: false,
      form: {},
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      isSystemList: [],
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.load();
    this.$store.dispatch("isShowNav", true); //恢复导航栏
  },
  methods: {
    async load() {
      const res = await getTemplateList(this.pageNo, this.pageSize);
      if (res.code == 0) {
        (this.invoiceData = res.data), (this.total = res.count);
      }
      this.invoiceData.forEach((item) => {
        if (item.isSystem == 0) {
          this.isSystemList.push(item);
        }
      });
    },
    addTemplate() {
      if (this.isSystemList.length < 5) {
        this.visible = true;
      } else {
        this.$message.warn("最多支持自定义5个模板！");
      }
    },
    cancel() {
      this.visible = false;
      this.form = {};
      this.$refs.ruleForm.resetFields();
    },
    // 改变页码
    PageNOchange(curPage) {
      this.pageNo = curPage;
      this.isSystem = [];
      this.load();
    },
    async handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let data = {
            name: this.form.name,
            isOpen: 0,
            isSystem: 0,
            detail: this.form.detail,
          };
          const res = await addTemplate(data);
          if (res.code == 0) {
            this.$message.success("创建成功！");
            (this.visible = false), this.$refs.ruleForm.resetFields();
            this.isSystemList = [];
            this.load(), (this.form = {});
          }
        }
      });
    },
    async cardSwitch(isOpen, record) {
      if (record.isSystem == 0) {
        let data = {
          code: record.code,
          isOpen: isOpen,
        };
        const res = await editTemplate(data);
        if (res.code == 0) {
          this.$message.success("修改成功！");
          this.isSystemList = [];
          this.load();
        }
      } else {
        this.$message.warn("系统模版无法修改！");
      }
    },
    async del(code) {
      const res = await delTemplate(code);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.isSystemList = [];
        this.load();
      }
    },
    look(record) {
      if (record.isSystem == 1) {
        this.$router.push({
          name: "lookTemplate",
          query: {
            code: record.code,
            member: this.isSystemList.length,
          },
        });
      } else {
        this.$router.push({
          name: "setupTemplate",
          query: {
            code: record.code,
            name: record.name,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.accountManagement {
  border: 10px solid #edeff2;
}
.moTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  margin: auto;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  color: $topTitleColor;
}
.table {
  width: 98%;
  margin: auto;
  margin-top: 20px;
  .rpa-table-header {
    overflow: hidden !important;
    margin-bottom: auto !important;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}
</style>
